export const siteSettings = {
  name: "HypeFly",
  title: "Hype Fly India | Premium and Limited Sneakers and Streetwear",
  description:
    "Shop From A Wide Range of Brands: Jordans, Yeezys, Dunks, Fear of God Essentials, Limited Edition & Authentic Products Online With Fast & Safe Delivery.",
  websiteUrl: "https://hypefly.co.in/",
  author: {
    name: "HypeFly",
    websiteUrl: "https://hypefly.co.in",
    address: "",
  },
  logo: {
    url: "/logo.webp",
    alt: "HypeFly",
    path: "/",
    width: 105,
    height: "auto",
  },
  defaultLanguage: "en",
  currencyCode: "INR",
  site_header: {
    menu: [
      // {
      //   id: 1,
      //   path: "/collections/black-friday-sale",
      //   label: "Black Friday Sale",
      // },
      {
        id: 2,
        path: "/collections/all-sneakers",
        label: "Sneakers",
        subMenu: [
          {
            id: 1,
            path: "/collections/all-sneakers",
            label: "All Sneakers",
            subLabel: "Shop from our entire collection of sneakers.",
          },
          {
            id: 2,
            path: "/collections/yeezys",
            label: "Yeezys",
            subLabel: "No one does it like Ye.",
            subMenu: [
              {
                id: 1,
                label: "Yeezy 350",
                path: "/collections/yeezy-350",
              },
              {
                id: 2,
                label: "Slides",
                path: "/collections/yeezy-slide",
              },
              {
                id: 3,
                label: "Foam RNNR",
                path: "/collections/yeezy-foamrunner",
              },
              {
                id: 4,
                label: "All Yeezys",
                path: "/collections/yeezys",
              },
            ],
          },
          {
            id: 3,
            path: "/collections/jordans",
            label: "Jordans",
            subLabel: "Straight from the MJ collection.",
            subMenu: [
              {
                id: 1,
                label: " Air Jordan 1 High",
                path: "/collections/air-jordan-1-high",
              },
              {
                id: 2,
                label: "Air Jordan 1 Mid",
                path: "/collections/air-jordan-1-mid",
              },
              {
                id: 3,
                label: "Air Jordan 1 Low",
                path: "/collections/air-jordan-1-low",
              },
              {
                id: 4,
                label: "Air Jordan 4",
                path: "/collections/jordan-4",
              },
              {
                id: 5,
                label: "All Jordans",
                path: "/collections/jordans",
              },
            ],
          },
          {
            id: 4,
            label: "Dunks",
            subLabel: "They'll make you want to buy a skateboard.",
            path: "/collections/dunks",
            subMenu: [
              {
                id: 1,
                label: "Dunk Low",
                path: "/collections/dunk-low",
              },
              {
                id: 2,
                label: "Dunk High",
                path: "/collections/dunk-high",
              },
              {
                id: 3,
                label: "All Dunks",
                path: "/collections/dunks",
              },
            ],
          },
          {
            id: 5,
            label: "New Balance",
            subLabel: "Where modern hype meets classic comfort.",
            path: "/collections/new-balance",
            subMenu: [
              {
                id: 1,
                label: "NB 550",
                path: "/collections/new-balance-nb-550",
              },
              {
                id: 2,
                label: "NB 530",
                path: "/collections/new-balance-nb-530",
              },
              {
                id: 3,
                label: "NB 2002R",
                path: "/collections/new-balance-nb-2002R",
              },
              {
                id: 4,
                label: "NB 9060",
                path: "/collections/new-balance-nb-9060",
              },
              {
                id: 5,
                label: "All New Balance",
                path: "/collections/new-balance",
              },
            ],
          },
          {
            id: 6,
            label: "On Running",
            subLabel: "Swiss-engineered running shoes for peak performance.",
            path: "/collections/on-running",
            subMenu: [
              {
                id: 1,
                label: "Cloud 5",
                path: "/collections/on-running-cloud-5",
              },
              {
                id: 2,
                label: "Cloud Monster",
                path: "/collections/on-running-cloud-monster",
              },
              {
                id: 3,
                label: "Cloud Monster 2",
                path: "/collections/on-running-cloud-monster-2",
              },
              {
                id: 4,
                label: "Cloud Nova",
                path: "/collections/on-running-cloud-nova",
              },
              {
                id: 5,
                label: "Cloud Runner",
                path: "/collections/on-running-cloud-runner-2",
              },
              {
                id: 6,
                label: "Cloud Tilt",
                path: "/collections/on-running-cloud-tilt",
              },
              {
                id: 7,
                label: "Cloud X 3",
                path: "/collections/on-running-cloud-x-3",
              },
              {
                id: 8,
                label: "Roger Advantage",
                path: "/collections/on-running-the-roger-advantage",
              },
            ],
          },
          {
            id: 7,
            label: "Air Force 1s",
            subLabel: "A timeless legend redefined for the next gen.",
            path: "/collections/air-force-1",
          },
          {
            id: 8,
            label: "Crocs",
            subLabel: "Get comfort with style",
            path: "/collections/crocs",
          },
          // {
          //   id: 9,
          //   label: "Onitsuka Tiger",
          //   subLabel: "Shop the latest styles now.",
          //   path: "/collections/onitsuka-tiger",
          // },
          {
            id: 10,
            label: "Grails",
            subLabel: "Shop from the holy-grail of shoes.",
            path: "/collections/grails",
          },
          {
            id: 11,
            label: "Other Collabs",
            subLabel: "Some of our favourite sneaker collabs.",
            path: "/collections/other-collabs",
          },
          // {
          //   id: 12,
          //   label: "Sneaker Packs",
          //   subLabel: "Curated Sneaker Packs",
          //   path: "/collections/sneaker-packs",
          // },
        ],
      },
      {
        id: 8,
        label: "Running",
        path: "/collections/running",
        subMenu: [
          {
            id: 1,
            label: "On Running",
            subLabel: "Swiss-engineered running shoes for peak performance.",
            path: "/collections/on-running",
            subMenu: [
              {
                id: 1,
                label: "Cloud 5",
                path: "/collections/on-running-cloud-5",
              },
              {
                id: 2,
                label: "Cloud Monster",
                path: "/collections/on-running-cloud-monster",
              },
              {
                id: 3,
                label: "Cloud Nova",
                path: "/collections/on-running-cloud-nova",
              },
              {
                id: 4,
                label: "Cloud Runner",
                path: "/collections/on-running-cloud-runner-2",
              },
              {
                id: 5,
                label: "Cloud Tilt",
                path: "/collections/on-running-cloud-tilt",
              },
              {
                id: 6,
                label: "Cloud X 3",
                path: "/collections/on-running-cloud-x-3",
              },
              {
                id: 7,
                label: "Roger Advantage",
                path: "/collections/on-running-the-roger-advantage",
              },
            ],
          },
          {
            id: 2,
            label: "Nike Running",
            subLabel: "Unleash Your Speed with Nike Running",
            path: "/collections/nike-running",
            subMenu: [
              {
                id: 1,
                label: "Alpha Fly",
                path: "/collections/alpha-fly",
              },
              {
                id: 2,
                label: "Infinity Run",
                path: "/collections/infinity-run-4",
              },
              {
                id: 3,
                label: "Invincible 3",
                path: "/collections/nike-running?categoryFilter=Invincible+3",
              },
              {
                id: 4,
                label: "Pegasus 41",
                path: "/collections/pegasus-41",
              },
              {
                id: 5,
                label: "Streak Fly",
                path: "/collections/streak-fly",
              },
              {
                id: 6,
                label: "Vapor Fly",
                path: "/collections/vapor-fly",
              },
              {
                id: 7,
                label: "Vomero",
                path: "/collections/vomero-17",
              },
              {
                id: 8,
                label: "Zoom Fly",
                path: "/collections/zoom-fly",
              },
            ],
          },
          {
            id: 3,
            label: "Hoka",
            subLabel: "Maximal cushioning for effortless, smooth runs.",
            path: "/collections/hoka",
            subMenu: [
              {
                id: 1,
                label: "Arahi",
                path: "/collections/hoka-arahi-7",
              },
              {
                id: 2,
                label: "Bondi 8",
                path: "/collections/hoka-bondi-8",
              },
              {
                id: 3,
                label: "Challenger 7",
                path: "/collections/hoka-challenger-7",
              },
              {
                id: 4,
                label: "Clifton 9",
                path: "/collections/hoka-clifton-9",
              },
              {
                id: 5,
                label: "Mach 6",
                path: "/collections/hoka-mach-6",
              },
              {
                id: 6,
                label: "Mach X",
                path: "/collections/hoka-mach-x",
              },
              {
                id: 7,
                label: "Rocket X",
                path: "/collections/hoka-rocket-x",
              },
            ],
          },
          {
            id: 4,
            label: "Asics",
            subLabel: "Designed for Superior Comfort and Performance",
            path: "/collections/asics",
            subMenu: [
              {
                id: 1,
                label: "Gel NYC",
                path: "/collections/asics",
              },
              {
                id: 2,
                label: "Cumulus 26",
                path: "/collections/gel-cumulus-26",
              },
              {
                id: 3,
                label: "Kayano Gel",
                path: "/collections/gel-kayano-31",
              },
              {
                id: 4,
                label: "Gel Nimbus",
                path: "/collections/gel-nimbus-26",
              },
              {
                id: 5,
                label: "GT 2000",
                path: "/collections/gt-2000-12",
              },
              {
                id: 6,
                label: "Metaspeed",
                path: "/collections/meta-speed-edge",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        label: "Streetwear",
        path: "/collections/all-apparel",
        subMenu: [
          {
            id: 1,
            label: "All Streetwear",
            subLabel: "Find the perfect fits for your footwear.",
            path: "/collections/all-apparel",
          },
          {
            id: 2,
            label: "Represent",
            subLabel:
              "Elevate your look with our exclusive Represent collection",
            path: "/collections/represent",
          },
          {
            id: 3,
            label: "Stussy ",
            subLabel:
              "Discover street-ready classics with our Stussy collection now.",
            path: "/collections/stussy",
          },
          {
            id: 4,
            label: "Eric Emanuel",
            subLabel:
              "Dive into style with Eric Emanuel’s iconic shorts collection.",
            path: "/collections/eric-emanuel",
          },
          {
            id: 5,
            label: "Essentials",
            subLabel: "All the streetwear essentials.",
            path: "/collections/essentials",
          },
          {
            id: 6,
            label: "Anti Social Social Club",
            subLabel: "Anti Social Social Club ®",
            path: "/collections/anti-social-social-club",
          },
          {
            id: 7,
            label: "Drew House",
            subLabel: "For all the drews.",
            path: "/collections/drew-house",
          },
          {
            id: 8,
            label: "Palm Angels",
            subLabel: "Shop our Palm Angels collection now",
            path: "/collections/palm-angels",
          },
          {
            id: 9,
            label: "Off White",
            subLabel: "Shop our Off White collection now",
            path: "/collections/off-white",
          },
          {
            id: 10,
            label: "OVO",
            subLabel: "Shop our OVO collection now",
            path: "/collections/ovo",
          },
          {
            id: 11,
            label: "Bape",
            subLabel: "Shop our Bape collection now",
            path: "/collections/bape",
          },
          // {
          //   id: 12,
          //   label: "Cactus Jack",
          //   subLabel: "Shop our Cactus Jack collection now",
          //   path: "/collections/cactus-jack",
          // },
          // {
          //   id: 13,
          //   label: "Vlone",
          //   subLabel: "Shop our Vlone collection now",
          //   path: "/collections/vlone",
          // },
          {
            id: 14,
            label: "Kenzo",
            subLabel: "Shop our Kenzo collection now",
            path: "/collections/kenzo",
          },
          {
            id: 15,
            label: "Special Prices",
            subLabel: "Special Prices for you",
            path: "/collections/special-prices",
          },
        ],
      },
      {
        id: 4,
        label: "Luxury",
        path: "/collections/luxury",
      },
      {
        id: 5,
        path: "/collections/all-accessories",
        label: "Accessories",
        subMenu: [
          {
            id: 1,
            label: "All Accessories",
            path: "/collections/all-accessories",
            subLabel: "Shop from our entire collection of accessories.",
          },
          {
            id: 2,
            label: "Watches",
            subLabel: "Timeless sophistication for every occasion.",
            path: "/collections/watches",
          },
          {
            id: 3,
            label: "Rhode Phone Cases",
            subLabel: "Shop the viral phone case now.",
            path: "/collections/rhode-beauty",
          },

          {
            id: 4,
            label: "Marc Jacobs",
            path: "/collections/marc-jacobs",
            subLabel: "Shop the Iconic Tote Bag Now!",
          },
          {
            id: 5,
            label: "Stanley Tumblers",
            subLabel: "Stay trendy and hydrated.",
            path: "/collections/stanley",
          },
          {
            id: 6,
            label: "Ray Ban Meta Smart Glasses",
            path: "/collections/sunglasses",
            subLabel: "Discover the new age of sunglasses now",
          },
          {
            id: 7,
            label: "Whoop",
            path: "/collections/whoop",
            subLabel: "Tracking your health has never been easier",
          },
          {
            id: 8,
            label: "Funko",
            subLabel: "Perfect additions to any collection.",
            path: "/collections/funko",
          },

          {
            id: 9,
            label: "Laces",
            subLabel:
              "Elevate your sneakers with a sprinkle of personal touch.",
            path: "/collections/laces",
          },
          {
            id: 10,
            label: "Socks",
            subLabel: "Browse the trendiest socks now !",
            path: "/collections/nofomo",
            subMenu: [
              {
                id: 1,
                label: "No FOMO",
                path: "/collections/nofomo",
              },
            ],
          },
        ],
      },
      // {
      //   id: 6,
      //   label: "Under 20K",
      //   path: "/collections/under-20k",
      // },
      {
        id: 7,
        label: "Sneaker Care",
        path: "/collections/sneaker-care",
      },
    ],
  },
};
